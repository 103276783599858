html {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  margin: 0;
  background: whitesmoke;
}

html,
body,
#root {
  height: 100%
}

section {
  background: whitesmoke;
  padding-bottom: 30px;
}

#contact {
  min-height: fit-content;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* HEADER SECTION */

.nav-link {
  cursor: pointer;
}

.navbar-nav a {
  font-size: 1.15rem;
}

.navbar .navbar-collapse {
  text-align: center;
}

header a {
  font-family: "Dosis", sans-serif;
  font-size: 1rem;
  color: #1d3557;
  cursor: pointer;
}

p {
  color: #1d3557;
}

.logo {
  width: 45px;
}

/* WELCOME SECTION */

.welcome,
.gallery {
  margin-top: 60px;
}

#welcome {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  height: auto;
  min-height: 90vh;
  padding-bottom: 20px;
}

#welcome-mobile {
  padding-bottom: 0px;
  height: auto;
  min-height: 90vh;
  padding-bottom: 20px;
}

#welcome p {
  font-size: 2rem;
  font-family: "Dosis", sans-serif;
}

.welcome-img-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-img-div .section-title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.welcome-img-div h1 {
  font-size: 2rem;
  font-weight: 700;
}

.welcome-img-wrapper {
  padding-bottom: 30px;
}

.welcome-img {
  width: 100%;
  height: 90%;
  border-radius: 4px;
  box-shadow: 0 8px 6px -6px #000;
  object-fit: cover;
}

/* INTRO SECTION */

.intro-wrapper {
  display: flex;
}

.intro-text-mobile {
  font-size: 1.5rem;
}

.intro-img {
  width: 100%;
  height: 85%;
  border-radius: 4px;
  box-shadow: 0 8px 6px -6px #000;
  object-fit: cover;
}

/* CAROUSEL SECTION */

#gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
}

.gallery-header {
  width: 100%;
}

#gallery .section-title {
  /* padding-top: 0; */
  /* padding-bottom: 20px; */
}

.carousel-caption {
  bottom: 0;
  top: auto;
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.carousel-indicators .active {
  background-color: black !important;
}

.carousel-indicators li {
  background-color: gray !important;
}

.carousel-item {
  padding: 7% 10%;
}

.gallery-wrapper {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1%;
}

.gallery-pic-div {
  cursor: pointer;
  width: 380px;
  margin: 1%;
}

.gallery-pic {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 8px 6px -6px #000;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f1faee;
  max-width: 100%;
}

.carousel-h1 h1 {
  font-size: 2rem;
}

h1 {
  font-size: 2rem;
  color: #1d3557;
  font-family: "Dosis", sans-serif;
}

.carousel-img {
  width: 100%;
  max-height: 75vh;
  object-fit: contain;
  text-align: center;
  outline: none;
  border-radius: 4px;
}

.carousel-img-mobile {
  width: 100%;
  max-height: 50vh;
  object-fit: contain;
  text-align: center;
  outline: none;
  border-radius: 4px;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-div {
  outline: none;
  border: none;
  width: 65% !important;
}
.slider-div-mobile {
  width: 100%;
}
.slider-div h3 {
  margin-bottom: 0;
}
.slider-div:active {
  outline: none;
}
.slider-div:focus {
  outline: none;
}

.carousel-img:active {
  outline: none;
}

.slick-slide img {
  display: inline;
}

.arrow {
  width: 70px;
  cursor: pointer;
}

.videos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 2%;
  max-width: 100%;
}

#map {
  min-height: auto !important;
}

.map-div {
  max-width: 850%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mapbox-directions-profile label {
  margin: 0;
}

.mapContainer {
  width: 100%;
  height: 400px;
  max-width: 100%;
  max-height: 100vh;
  box-shadow: 0 8px 6px -6px #000;
  border-radius: 4px;
}

.mapContainerMobile {
  width: 100%;
  height: 70vh;
  max-width: 100%;
  max-height: 100vh;
}

.form-col {
  padding-top: 20px;
}

.submit-button {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

input {
  width: 200px;
  height: 30px;
  background: transparent;
  box-shadow: 0 8px 6px -6px #000;
}

textarea {
  width: 400px;
  height: 120px;
  background: transparent;
  resize: none !important;
  box-shadow: 0 8px 6px -6px #000;
}

.x {
  border: 1px solid;
  border-radius: 20px;
  float: right;
}

.x:hover {
  background-color: #343940;
  color: white;
  border: 1px solid;
}

.x-div {
  position: absolute;
  padding-right: 5%;
  margin-top: 9%;
  width: 100%;
}

#video {
  text-align: center;
}

.slick-arrow {
  width: 50px;
  z-index: 1;
  background: transparent !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 50px;
  color: black;
}

.slick-next:before {
  margin-left: 50px;
}

.slick-prev:before {
  position: absolute;
  top: 0;
  left: -50px;
}

.video-wapper {
  margin-bottom: 20px;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 1px darkgray;
}

.map-wrapper iframe {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  right: 0;
  border: 0;
}

.video {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 8px 6px -6px #000;
}

.video iframe {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  right: 0;
  border: 0;
}

.credits {
  margin-top: 30px;
}